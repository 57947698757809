import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Link } from '@mui/material';
import { ROUTES_PATH } from '../constants';

interface Props {
  children: ReactNode;
}

interface State {
  error: Error;
  errorInfo: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: undefined,
      errorInfo: undefined,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  public render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (error) {
      return (
        <Dialog open>
          <DialogTitle id="dialog-title">
            An unexpected error occurred
          </DialogTitle>
          <DialogContent dividers>
            <Link href={window.location.href}>Refresh the page</Link> or{' '}
            <Link href={ROUTES_PATH.DASHBOARD}>return to dashboard</Link> to try
            to recover.
            <pre>{window.location.href}</pre>
            <Box component="details" sx={{ marginTop: '1em' }}>
              {error.toString()}
              <Box component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
                {errorInfo.componentStack}
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
