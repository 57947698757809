import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import yaml from 'js-yaml';
import { saveAs } from 'file-saver';
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AddRounded,
  DescriptionRounded,
  IosShareOutlined,
  MoreVertRounded,
} from '@mui/icons-material';
import Modal from '../../../common/Diagram/library/Modal';
import QuickAccessButton from '../../../common/QuickAccessButton';
import { RedoIcon, TrashIcon, UndoIcon } from '../../../icons';
import { useGetVisualsQuery } from '../../../redux/services/visuals/api';
import {
  useCreateSystemConfigurationTemplateMutation,
  useGetSystemConfigurationTemplatesQuery,
  useUpdateSystemConfigurationTemplateMutation,
} from '../../../redux/services/systemConfigurationTemplate/api';
import {
  useGetProjectsQuery,
  useUpdateProjectYAMLMutation,
} from '../../../redux/services/projects/api';
import { REDIRECT_URI, ROUTES_PATH } from '../../../constants';
import useSnackbar from '../../../hooks/useSnackbar';
import {
  useCreateAttributesMutation,
  useValidateAttributesMutation,
} from '../../../redux/services/schemaRecords/api';
import { VisualResponse } from '../../../redux/services/visuals/types';
import { formatErrorMessage } from '../../../utils/helper';
import { isForbiddenError } from '../../../redux/utils';

const Actions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    width: '100%',
  },
  '& .favBtn': {
    width: '36px',
    height: '36px',
    marginRight: theme.spacing(2),
  },
}));

const findAllInstruments = (obj: any) => {
  if (!obj) return null;
  return Object.entries(obj).map(([key, value]) => {
    if (!value) return null;
    if (key === 'instruments' && typeof value === 'object') return value;
    if (key !== 'instruments' && typeof value === 'object')
      return findAllInstruments(value);
    return null;
  });
};

const flairsToRowsInputs = ({
  obj,
  indent,
}: {
  obj: VisualResponse['flairs'];
  indent: number;
}) => {
  if (!obj) return '{}';
  const indents = Array(indent).join(' ');
  const flairs = Object.keys(obj)
    .filter((key) => obj[key].type !== 'portAnchor')
    .map((key) => `${indents}${key}: $.none\n`);
  if (flairs.length < 1) return '{}';
  return ['\n', ...flairs].join('').replace(/\n$/, '');
};

function flattenFlairs(
  flairs: object,
  acc: object,
  space: number,
  flattenedKey?: string,
) {
  const indent = Array(space).join(' ');
  const valuePattern = /^[a-zA-Z0-9.,\-_]+$/;
  Object.keys(flairs).forEach((key) => {
    if (flairs[key] === 0 || flairs[key]) {
      let newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = `${flattenedKey}.${key}`;
      }
      const value = flairs[key];
      if (typeof value === 'object') {
        acc[newKey] = `${indent}${key}:\n`;
        flattenFlairs(value, acc, space + 2, newKey);
      } else {
        const renderValue = valuePattern.test(value) ? value : `'${value}'`;
        acc[newKey] = `${indent}${key}: ${renderValue ?? '$.none'}\n`;
      }
    }
  });
}

function flairsToRows(obj) {
  const flattenedFlairs = {};
  flattenFlairs(obj, flattenedFlairs, 9);
  return Object.values(flattenedFlairs).join('').replace(/\n$/, '');
}

const ConfigrationActions = ({
  desktop,
  libOpened,
  isLoadingVisuals,
  fileInputRef,
  textValue,
  configName,
  configData,
  configID,
  configType,
  setMorePopupElement,
  setLibOpened,
  setIsImport,
  setTextValue,
  setIsFileUpload,
  setConfigNameInvalid,
  morePopupElement,
  handleDeleteConfigWindow,
  setIsUpdated,
  handleExit,
  isUpdated,
  isFirstInit,
  onUndo,
  onRedo,
  yamlInstance,
  isEditable,
}: any) => {
  const [oldValue, setOldValue] = useState(textValue);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!oldValue && textValue) {
      setOldValue(textValue);
    }
  }, [oldValue, textValue]);

  useEffect(() => {
    if (isFirstInit) {
      setOldValue(textValue);
    }
  }, [isFirstInit]);

  const { data: visualsSelector } = useGetVisualsQuery();
  const { data: systemConfigurationTemplates = [] } =
    useGetSystemConfigurationTemplatesQuery(undefined);
  const { data: projects } = useGetProjectsQuery(undefined);
  const [
    updateProjectYAML,
    {
      isSuccess: isProjectUpdateSuccessful,
      error: errorProject,
      isLoading: isProjectsUpdateLoading,
    },
  ] = useUpdateProjectYAMLMutation();
  const [
    createTempalte,
    {
      data: createTemplateData,
      isSuccess: isTemplateCreateSuccessful,
      error: templateCreateError,
      isLoading: isTemplateCreateLoading,
    },
  ] = useCreateSystemConfigurationTemplateMutation();
  const [
    updateTemplate,
    {
      isSuccess: isTemplateUpdateSuccessful,
      error: templateUpdateError,
      isLoading: isTemplateUpdateLoading,
    },
  ] = useUpdateSystemConfigurationTemplateMutation();
  const [
    validateAttributes,
    { isLoading: isValidateAttributesMutationLoading },
  ] = useValidateAttributesMutation();
  const [
    createAttributesMany,
    { isLoading: isCreateAttributesMutationLoading },
  ] = useCreateAttributesMutation();
  const { showSuccess, showError } = useSnackbar();

  const handleLibOpen = () => {
    setLibOpened(true);
  };

  const handleLibClose = () => {
    setLibOpened(false);
  };

  const pasteHandler = (newVisualId: string) => {
    setIsImport(true);
    const selectedComponent = visualsSelector.find((v) => newVisualId === v.id);
    const { components, visuals } = yaml.load(textValue);

    const isNodeExists = Object.keys(components).find(
      (name) => name === selectedComponent.name,
    );

    const isVisualsExists = visuals
      ? Object.keys(visuals).find((name) => name === selectedComponent.name)
      : undefined;

    const newNodeText = ` ${
      isNodeExists
        ? `${selectedComponent.name} ${Object.keys(components)?.length}`
        : selectedComponent.name
    }:
    render: 
      - 0
      - 0
    instruments: {}
    visual:
      $ref: "#/visuals/${selectedComponent.name}"
      input: ${flairsToRowsInputs({
        obj: selectedComponent?.flairs,
        indent: 9,
      })}
      flairs:
${flairsToRows(selectedComponent?.flairs)}
      size:
        height: ${selectedComponent?.size?.height || 72}
        width: ${selectedComponent?.size?.width || 72}`;
    const newVisualsText = isVisualsExists
      ? ''
      : ` ${selectedComponent.name}:
    flairs: {}
    id: ${newVisualId}
    version: ${selectedComponent.version}`;

    const visualsText = `\n ${newVisualsText}`;

    setTextValue((prev) =>
      prev
        .replace('components:', `components:\n ${newNodeText}`)
        .replace('visuals: {}', 'visuals:')
        .replace('visuals:', `visuals:${newVisualsText ? visualsText : ''}`),
    );
  };

  const exactName = useMemo(() => {
    try {
      const jsonValue = yaml.load(textValue);
      if (jsonValue.name === configName) {
        return configName;
      }
    } catch (e) {
      console.warn({ e });
    }
    return undefined;
  }, [configName, textValue]);

  const buttonDisabledByRequest = useMemo(
    () =>
      isProjectsUpdateLoading ||
      isTemplateCreateLoading ||
      isTemplateUpdateLoading ||
      isValidateAttributesMutationLoading ||
      isCreateAttributesMutationLoading,
    [
      isProjectsUpdateLoading,
      isTemplateCreateLoading,
      isTemplateUpdateLoading,
      isValidateAttributesMutationLoading,
      isCreateAttributesMutationLoading,
    ],
  );

  const handleValidateTemplate = async () => {
    const data = yaml.load(textValue);
    const attributes = findAllInstruments(data)
      .flat(20)
      .filter((v) => v)
      .filter((v) => Object.keys(v).length > 0)
      .map((v) =>
        Object.values(v).map((ins) =>
          // @ts-ignore
          Object.values(ins?.attributes).map((vInner) => vInner),
        ),
      )
      .flat(20);

    const resultValidation = await validateAttributes(attributes);
    await createAttributesMany({
      // @ts-ignore
      validatedResults: resultValidation.data,
    });
  };

  const handleSaveConfigTemplate = async () => {
    await handleValidateTemplate();
    const isExists =
      Boolean(
        systemConfigurationTemplates.find(({ name }) => name === configName),
      ) || Boolean(projects.find(({ id }) => id === configID));

    if (exactName && configName === '') {
      showError("Can't use empty name");
      setConfigNameInvalid(true);
    }

    if (exactName && configName !== '' && !isExists && !configID) {
      if (configType === 'template') {
        await createTempalte(textValue);
      }
    }

    if (exactName && configName !== '' && isExists && configID) {
      if (configType === 'template') {
        await updateTemplate({ id: configID, data: textValue });
      }
      if (configType === 'project') {
        await updateProjectYAML({ id: configID, data: textValue });
      }
    }

    if (exactName && configName !== '' && isExists && !configID) {
      showError('Name already in use');
      setConfigNameInvalid(true);
    }
  };

  useEffect(() => {
    if (isProjectUpdateSuccessful || isTemplateUpdateSuccessful) {
      showSuccess(`${exactName} was successfully updated`);
      setIsUpdated(true);
    }
  }, [isProjectUpdateSuccessful, isTemplateUpdateSuccessful]);

  useEffect(() => {
    if (isTemplateCreateSuccessful) {
      showSuccess(`${exactName} was successfully created`);
      setIsUpdated(true);
    }
  }, [isTemplateCreateSuccessful]);

  useEffect(() => {
    if (createTemplateData) {
      const basedOn = createTemplateData?.id;

      const createProjectData = JSON.parse(
        sessionStorage.getItem('createProjectData'),
      );
      sessionStorage.setItem(
        'createProjectData',
        JSON.stringify({
          ...createProjectData,
          basedOn: basedOn || '',
        }),
      );
      handleExit();
    }
  }, [createTemplateData]);

  useEffect(() => {
    if (!errorProject) return;
    if (isForbiddenError(errorProject)) {
      showError('You do not have permission to update the project');
      return;
    }
    showError(
      `${exactName} wasn't updated: ${formatErrorMessage(errorProject)}`,
    );
  }, [errorProject]);

  useEffect(() => {
    if (!templateCreateError) return;
    if (isForbiddenError(templateCreateError)) {
      showError('You do not have permission to create a template');
      return;
    }
    showError(
      `${exactName} wasn't updated: ${formatErrorMessage(templateCreateError)}`,
    );
  }, [templateCreateError]);

  useEffect(() => {
    if (!templateUpdateError) return;
    if (isForbiddenError(templateUpdateError)) {
      showError('You do not have permission to update the template');
      return;
    }
    showError(
      `${exactName} wasn't updated:  ${formatErrorMessage(
        templateCreateError,
      )}`,
    );
  }, [templateUpdateError]);

  const handleMorePopup = (ev: React.MouseEvent<HTMLButtonElement>) => {
    setMorePopupElement(ev.currentTarget);
  };

  const handleFileUploading = async (event: ChangeEvent<HTMLInputElement>) => {
    const [file] = event.currentTarget.files;
    const innerData = await file.text();

    setIsFileUpload(true);
    setTextValue(innerData);
    if (fileInputRef?.current?.value) fileInputRef.current.value = null;
    if (event?.currentTarget?.value) event.currentTarget.value = null;
  };

  const handleSaveYaml = () => {
    setMorePopupElement(null);
    const blob = new Blob([textValue]);
    saveAs(blob, `${exactName}.yaml`);
  };

  useEffect(() => {
    setOldValue(textValue);
  }, [isUpdated]);

  return (
    <>
      <Actions>
        {!isMobile && (
          <>
            {configName?.length !== 0 &&
              oldValue !== textValue &&
              !isUpdated &&
              !sessionStorage.getItem(REDIRECT_URI) && (
                <Button
                  variant="contained"
                  onClick={handleSaveConfigTemplate}
                  sx={{ marginRight: 2 }}
                  disabled={
                    configName?.length === 0 ||
                    buttonDisabledByRequest ||
                    !exactName
                  }
                  id="CreateConfig-save_changes"
                >
                  {desktop ? 'Save changes' : 'Save'}
                </Button>
              )}
            <Tooltip title="Undo">
              <IconButton
                onClick={onUndo}
                className="outlined"
                disabled={!yamlInstance?.getModel()?.canUndo()}
              >
                <UndoIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Redo">
              <IconButton
                onClick={onRedo}
                sx={{ mx: 2 }}
                className="outlined"
                disabled={!yamlInstance?.getModel()?.canRedo()}
              >
                <RedoIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Button
          onClick={handleLibOpen}
          color="secondary"
          variant="contained"
          sx={{
            minWidth: 36,
            ml: { xs: 0, sm: 'auto' },
            px: { xs: 1, md: 2 },
          }}
          startIcon={desktop ? <AddRounded /> : null}
          disabled={!isEditable}
          id="CreateConfig-add_visual"
        >
          {desktop ? 'Add visual element' : <AddRounded />}
        </Button>
        {isMobile &&
          configName?.length !== 0 &&
          oldValue !== textValue &&
          !isUpdated &&
          !sessionStorage.getItem(REDIRECT_URI) && (
            <Button
              variant="contained"
              onClick={handleSaveConfigTemplate}
              sx={{ ml: 2 }}
              disabled={
                configName?.length === 0 ||
                buttonDisabledByRequest ||
                !exactName ||
                !isEditable
              }
              id="CreateConfig-save_changes"
            >
              {desktop ? 'Save changes' : 'Save'}
            </Button>
          )}
        <Modal
          open={libOpened}
          onClose={handleLibClose}
          pasteHandler={pasteHandler}
        />
        {desktop && (
          <Button
            variant="outlined"
            component="label"
            sx={{ ml: 2, px: { xs: 1, md: 2 } }}
            disabled={isLoadingVisuals || !isEditable}
            id="EditorView_Button-ImportFromFile"
          >
            Import from file
            <input
              ref={fileInputRef}
              type="file"
              hidden
              onChange={handleFileUploading}
              accept=".yaml"
            />
          </Button>
        )}
        <Box sx={{ ml: { xs: 'auto', sm: 2 }, mr: 2 }}>
          <Button
            id="CreateConfig-exit_button"
            variant="contained"
            onClick={handleExit}
          >
            Exit
          </Button>
        </Box>
        {desktop && configData && configID && (
          <QuickAccessButton
            className="favBtn"
            id="ProjectConfig-QuickAccess_button"
          />
        )}
        <Tooltip title="More">
          <IconButton
            id="EditConfig-more_action_button"
            className="outlined"
            onClick={handleMorePopup}
          >
            <MoreVertRounded />
          </IconButton>
        </Tooltip>
      </Actions>
      <Menu
        id="user-action-menu"
        anchorEl={morePopupElement}
        keepMounted
        open={Boolean(morePopupElement)}
        onClose={() => setMorePopupElement(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiList-root': {
            minWidth: !desktop && '230px',
          },
        }}
      >
        {isMobile && (
          <>
            <MenuItem
              disabled={!yamlInstance?.getModel()?.canUndo()}
              onClick={onUndo}
              id="EditConfig-undo-mobile"
            >
              <ListItemIcon>
                <UndoIcon />
              </ListItemIcon>
              Undo
            </MenuItem>
            <MenuItem
              disabled={!yamlInstance?.getModel()?.canRedo()}
              onClick={onRedo}
              id="EditConfig-redo-mobile"
            >
              <ListItemIcon>
                <RedoIcon />
              </ListItemIcon>
              Redo
            </MenuItem>
          </>
        )}
        <MenuItem onClick={handleSaveYaml} id="EditConfig-save_as_yaml">
          <ListItemIcon>
            <IosShareOutlined />
          </ListItemIcon>
          Save as YAML
        </MenuItem>
        {!desktop && (
          <>
            {configData && configID && (
              <QuickAccessButton
                asListItem
                id="ProjectConfig-QuickAccess_button_mobile"
              />
            )}
            <MenuItem
              onClick={() => setMorePopupElement(null)}
              component="label"
              disabled={isLoadingVisuals || !isEditable}
              id="EditorView_Button-ImportFromFile"
            >
              <ListItemIcon>
                <DescriptionRounded />
              </ListItemIcon>
              Import from file
              <input
                ref={fileInputRef}
                type="file"
                hidden
                onChange={handleFileUploading}
                accept=".yaml"
              />
            </MenuItem>
          </>
        )}
        {configData && configID ? (
          <MenuItem
            sx={{ color: 'error.main' }}
            onClick={handleDeleteConfigWindow}
            id="EditConfig-delete_button"
          >
            <ListItemIcon>
              <TrashIcon />
            </ListItemIcon>
            Delete
          </MenuItem>
        ) : (
          <MenuItem
            sx={{ color: 'error.main' }}
            component={Link}
            to={ROUTES_PATH.PROJECTS}
            id="CreateConfig-delete_button"
          >
            <ListItemIcon>
              <TrashIcon />
            </ListItemIcon>
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ConfigrationActions;
