import { createActions } from 'redux-actions';
import { identity } from '../../utils';

export const {
  setBreadcrumbInitial,
  setBreadcrumb,
  setBreadcrumbEmpty,
  setBreadcrumbStateValue,
} = createActions({
  SET_BREADCRUMB_INITIAL: identity,
  SET_BREADCRUMB: identity,
  SET_BREADCRUMB_EMPTY: identity,
  SET_BREADCRUMB_STATE_VALUE: identity,
});
