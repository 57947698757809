import React, {
  ChangeEvent,
  lazy,
  memo,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SystemList from '../../components/system/Lists/SystemList';
import Loader from '../../common/Loader';
import CreateSystem from '../../common/Dialogs/CreateSystem';
import {
  CONTROL_STATUSES,
  MACHINE_STATUSES,
  ROUTES_PATH,
  SYSTEMS_STATUSES,
} from '../../constants';
import {
  setBreadcrumb,
  setBreadcrumbInitial,
} from '../../redux/modules/breadcrumbs';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ContentWrap, Root } from './styles';
import { useGetSitesQuery } from '../../redux/services/sites/api';
import { useGetSystemsQuery } from '../../redux/services/systems/api';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import { setActiveSites } from '../../redux/modules/sites/slice';
import TopBar from '../../components/system/components/TopBar';
import Breadcrumbs from '../../common/Breadcrumbs';
import {
  useCreatePreferencesMutation,
  useGetPreferencesQuery,
  usePatchPreferencesMutation,
} from '../../redux/services/preferences/api';
import { retry } from '../../utils/retry';
import { setRedirectEmpty } from '../../redux/modules/redirect';
import EmptyListComponent from '../../components/system/SystemListEmpty';
import { UnauthorizedPageErrorBoundary } from '../../common/UnauthorizedErrorBoundary';
import { isForbiddenError } from '../../redux/utils';

// @ts-ignore
const SystemMap = lazy(() =>
  retry(() => import('../../components/system/map')),
);

const SystemPage = () => {
  const defaultState = SYSTEMS_STATUSES[0];
  const params = useParams<{ state: string }>();
  const {
    data: getPreferences,
    isLoading: getPreferencesLoading,
    isError,
  } = useGetPreferencesQuery(null);

  const [createPreference] = useCreatePreferencesMutation();
  const [changePreference] = usePatchPreferencesMutation();
  const statuses = SYSTEMS_STATUSES;
  const controlStatuses = CONTROL_STATUSES;
  const machineStatuses = MACHINE_STATUSES;

  const activeSites = useAppSelector(
    (state: IAppState) => state.activeSites?.activeSites,
  );
  const [status, setStatus] = useState([defaultState]);
  const [searchValue, setSearchValue] = useState('');

  const isActiveSitesLoaded = useAppSelector(
    (state) => state.activeSites.loaded,
  );
  const { data: sites, isLoading: isSitesLoading } = useGetSitesQuery(null);
  const {
    data: systems = [],
    isLoading: isSystemsLoading,
    error: systemsError,
  } = useGetSystemsQuery(undefined);
  useEffect(() => {
    if (getPreferencesLoading || isError) {
      setStatus([...status]);
    }
    if (!getPreferencesLoading && !isError) {
      if (getPreferences?.preferences?.systemStatus) {
        setStatus([...getPreferences?.preferences?.systemStatus]);
      }
    }
  }, [getPreferences, isError]);
  function updatePreferences() {
    changePreference({
      preferences: {
        systemStatus: [defaultState],
      },
    });
  }
  function createPreferences() {
    createPreference({
      preferences: {
        systemStatus: [defaultState],
      },
    });
  }
  function updateOrCreatePreferences() {
    setStatus([defaultState]);
    if (isError) {
      createPreferences();
    }
    updatePreferences();
  }
  const localSystems = useMemo(() => {
    if (systems.length > 0) {
      const activeFilter = status.map((stat) => stat?.toLowerCase());

      const filteredSystems = systems
        ?.filter((system) =>
          isActiveSitesLoaded ? activeSites.indexOf(system?.site) > -1 : true,
        )
        ?.filter(({ state, display, machineState }) => {
          // has control and machine statuses filter both
          if (
            activeFilter.some((v) => controlStatuses.indexOf(v) > -1) &&
            activeFilter.some((v) => machineStatuses.indexOf(v) > -1)
          ) {
            if (
              activeFilter.indexOf((machineState || '').toLowerCase()) > -1 &&
              activeFilter.indexOf(display?.toLowerCase()) > -1
            ) {
              return true;
            }
            if (
              activeFilter.indexOf((machineState || '').toLowerCase()) > -1 &&
              activeFilter.indexOf('running') > -1 &&
              state &&
              state > 0
            ) {
              return true;
            }
            if (
              activeFilter.indexOf((machineState || '').toLowerCase()) > -1 &&
              activeFilter.indexOf('unknown') > -1 &&
              display === undefined
            ) {
              return true;
            }
          }

          // has any of control and machine statuses filter
          if (
            activeFilter.every((v) => controlStatuses.indexOf(v) > -1) ||
            activeFilter.every((v) => machineStatuses.indexOf(v) > -1)
          ) {
            if (activeFilter.indexOf((machineState || '').toLowerCase()) > -1) {
              return true;
            }
            if (activeFilter.indexOf(display?.toLowerCase()) > -1) {
              return true;
            }
            if (activeFilter.indexOf('running') > -1 && state && state > 0) {
              return true;
            }
            if (activeFilter.indexOf('unknown') > -1 && display === undefined) {
              return true;
            }
          }

          if (activeFilter.indexOf('all') > -1) {
            return true;
          }
          return false;
        })
        ?.filter(
          (system) =>
            system.name?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1,
        )
        ?.map(({ id }) => id);

      return filteredSystems;
    }
    return [];
  }, [systems, activeSites, searchValue, status]);

  useEffect(() => {
    if (sites)
      if (sites?.length > 0) {
        dispatch(
          setActiveSites({
            activeSites: sites.map((it) => it.id),
            loaded: true,
          }),
        );
      }
  }, [sites]);

  const [mapView, setMapView] = useState(params?.state === 'map' || false);
  const [isCreate, setCreate] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [redirect, setRedirect] = useState<string>('');

  const history = useHistory();
  const dispatch = useAppDispatch();
  const redirectId = useSelector((state: IAppState) => state.redirect);

  useEffect(() => {
    dispatch(
      setBreadcrumbInitial({ name: 'Home', url: ROUTES_PATH.DASHBOARD }),
    );
    dispatch(
      setBreadcrumb({ name: 'Systems', url: history.location.pathname }),
    );
  }, []);

  const loading = useMemo(
    () => isSystemsLoading || isSitesLoading || getPreferencesLoading,
    [isSystemsLoading, isSitesLoading, getPreferencesLoading],
  );

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleStatusChange = (event: any) => {
    const val: string[] = event?.target.value;

    if (val.length === 0) {
      updateOrCreatePreferences();
      return;
    }
    if (val[val.length - 1]?.toLowerCase().startsWith('all')) {
      updateOrCreatePreferences();
      return;
    }
    setStatus(
      val.filter((v) => v?.toLowerCase() !== defaultState?.toLowerCase()),
    );
    if (isError) {
      createPreference({
        preferences: {
          systemStatus: [
            ...val.filter(
              (v) => v?.toLowerCase() !== defaultState?.toLowerCase(),
            ),
          ],
        },
      });
    }
    changePreference({
      preferences: {
        systemStatus: [
          ...val.filter(
            (v) => v?.toLowerCase() !== defaultState?.toLowerCase(),
          ),
        ],
      },
    });
  };

  useEffect(() => {
    if (status.length === 0) {
      updateOrCreatePreferences();
    }
  }, [status]);

  const handleCreateOpen = () => {
    setCreate(true);
  };

  const handleCreateClose = () => {
    setCreate(false);
  };

  const handleExpandSearch = () => {
    setSearch(true);
  };

  const handleCollapseSearch = () => {
    setSearch(false);
    setSearchValue('');
  };

  const handleToggleFilter = () => {
    setFilter(!isFilter);
  };

  useEffect(() => {
    if (redirectId && redirectId.route !== '') {
      dispatch(setRedirectEmpty());
      setRedirect(redirectId.route || '');
    }
  }, [redirectId?.route]);

  useEffect(() => {
    if (redirect) {
      history.push(redirect);
    }
  }, [redirect]);

  return (
    <Root className={!loading ? 'loaded' : ''}>
      <UnauthorizedPageErrorBoundary isError={isForbiddenError(systemsError)}>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Breadcrumbs isMobile />
        </Box>
        <TopBar
          mapView={mapView}
          setMapView={setMapView}
          isSearch={isSearch}
          searchValue={searchValue}
          handleSearchInput={handleSearchInput}
          handleCollapseSearch={handleCollapseSearch}
          status={status}
          statuses={statuses}
          handleStatusChange={handleStatusChange}
          isFilter={isFilter}
          handleToggleFilter={handleToggleFilter}
          sites={sites || []}
          activeSites={activeSites}
          handleExpandSearch={handleExpandSearch}
          handleCreateOpen={handleCreateOpen}
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            <ContentWrap>
              {mapView ? (
                <Suspense fallback={<Loader />}>
                  <SystemMap systemsLocal={localSystems} />
                </Suspense>
              ) : (
                <>
                  {systems.length > 0 ? (
                    <SystemList
                      status={status}
                      systems={localSystems}
                      isSearch={isSearch}
                      isFilter={isFilter}
                      handleCreateOpen={handleCreateOpen}
                      handleToggleFilter={handleToggleFilter}
                      onCancelSearch={handleCollapseSearch}
                      onExpandSearch={handleExpandSearch}
                      handleStatusChange={handleStatusChange}
                      systemStatuses={statuses}
                      systemStatus={status}
                      searchValue={searchValue}
                      handleSearchInput={handleSearchInput}
                    />
                  ) : (
                    <EmptyListComponent
                      handleCreateSystemOpen={handleCreateOpen}
                      searchValue={searchValue}
                    />
                  )}
                </>
              )}
            </ContentWrap>
          </>
        )}
        <CreateSystem closeModal={handleCreateClose} isOpened={isCreate} />
      </UnauthorizedPageErrorBoundary>
    </Root>
  );
};

export default memo(SystemPage);
