import { handleActions } from 'redux-actions';
import {
  setActiveDashboard,
  setControlSubscriptionStatusesLoaded,
  setMachineSubscriptionStatusesLoaded,
  setToggleAccessible,
  setTrendViewState,
} from './actions';
import { environment } from '../../../env';

const initialState = {
  isMap: environment() === 'cloud',
  isToggleAccessible: false,
  machineStatusLoaded: false,
  controlStatusLoaded: false,
  linkView: undefined,
};

const viewReducer = handleActions(
  {
    [setActiveDashboard]: (state, { payload }) => ({
      ...state,
      isMap: payload,
    }),
    [setToggleAccessible]: (state, { payload }) => ({
      ...state,
      isToggleAccessible: payload,
    }),
    [setMachineSubscriptionStatusesLoaded]: (state, { payload }) => ({
      ...state,
      machineStatusLoaded: payload,
    }),
    [setControlSubscriptionStatusesLoaded]: (state, { payload }) => ({
      ...state,
      controlStatusLoaded: payload,
    }),
    [setTrendViewState]: (state, { payload }) => ({
      ...state,
      linkView: payload,
    }),
  },
  initialState,
);

export default viewReducer;
