/* eslint-disable no-useless-escape */
export const yamlSchema = [
  {
    uri: 'https://myserver/foo-schema.json',
    fileMatch: ['*'],
    schema: {
      id: 'https://myserver/foo-schema.json',
      type: 'object',
      properties: {
        name: {
          type: 'string',
        },
        description: {
          type: 'string',
        },
        components: {
          title: 'Components',
          description:
            'Components field describes components of the system template.',
          patternProperties: {
            '^.*$': {
              description: 'This property will be used as component name',
              type: 'object',
              properties: {
                instruments: {
                  title: 'Component instruments',
                },
                render: {
                  title: 'Component render property',
                  description: `
Rendering position starting from the center of component
x - first argument, y - second argument
    position:
      - -300
      - 300`,
                },
                ports: {
                  title: 'Used ports description',
                  patternProperties: {
                    '^.*$': {
                      description:
                        "This property will be used as component's port name",
                      properties: {
                        anchor: {
                          type: 'string',
                        },
                      },
                      required: ['anchor'],
                    },
                  },
                },
                visual: {
                  type: 'object',
                  properties: {
                    $ref: {
                      description:
                        'ref to some visual from visuals root property',
                    },
                    flairs: {
                      type: 'object',
                      patternProperties: {
                        '^.*$': {
                          type: 'object',
                          placement: {
                            type: 'object',
                            description: `
Rendering position starting from the center of component
x - first argument, y - second argument
    position:
      x: -300
      y: 300`,
                            properties: {
                              x: { type: 'number' },
                              y: { type: 'number' },
                            },
                          },
                          default: { type: ['string', 'number'] },
                          suffix: {
                            type: 'string',
                          },
                          textSize: {
                            type: 'string',
                          },
                          maximum: { type: 'number' },
                          required: ['placement'],
                        },
                      },
                    },
                    input: {},
                    size: {
                      type: 'object',
                      description: `
Size of the component in pixels

    size:
      width: 300
      height: 300`,
                      properties: {
                        width: { type: 'number' },
                        height: { type: 'number' },
                      },
                      reqired: ['width', 'height'],
                    },
                  },
                },
                required: ['instruments', 'render'],
              },
            },
          },
        },
        connectors: {
          title: 'Connectors properties',
          description:
            'Connectors field describes connectors of the system template.',
          patternProperties: {
            '^.*$': {
              description: 'This property will be used as connector name',
              type: 'object',
              properties: {
                instruments: {
                  type: 'object',
                  required: true,
                },
                ports: {
                  title: 'Connection with 2 refs from/to',
                },
              },
              required: ['instruments', 'ports'],
            },
          },
        },
        visuals: {
          type: 'object',
          patternProperties: {
            '^.*$': {
              type: 'object',
              title: 'Visuals name',
              properties: {
                flairs: {
                  type: 'object',
                  required: true,
                },
                id: {
                  type: 'string',
                  required: true,
                },
                version: {
                  type: 'number',
                  required: true,
                },
              },
              reqired: ['flairs', 'id', 'version'],
              additionalProperties: false,
            },
          },
        },
      },
    },
  },
];
