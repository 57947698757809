export const loadState = () => {
  try {
    const quickAccess = JSON.parse(localStorage.getItem('quickAccess')) || [];
    const breadcrumbs = JSON.parse(sessionStorage.getItem('breadcrumbs'));
    const isDemo = JSON.parse(localStorage.getItem('isDemo')) || false;

    const serializedState = {
      quickAccess,
      breadcrumbs,
      isDemo,
    };

    if (Object.values(serializedState).every((v) => !v)) {
      return undefined;
    }

    return serializedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    Object.entries(state).forEach(([key, value]) => {
      if (key === 'breadcrumbs') {
        sessionStorage.setItem(key, JSON.stringify(value));
      } else {
        localStorage.setItem(key, JSON.stringify(value));
      }
    });
  } catch (err) {
    // err
  }
};
