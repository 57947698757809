import { handleActions } from 'redux-actions';
import {
  setBreadcrumb,
  setBreadcrumbEmpty,
  setBreadcrumbInitial,
  setBreadcrumbStateValue,
} from '.';

const storedState =
  JSON.parse(sessionStorage.getItem('breadcrumbs') || '{}') || {};

const initialState = {
  current: storedState?.current || {},
  previously: storedState?.previously || [],
  stateValue: storedState?.stateValue || '0',
};

const newStateHandler = (newStateIndex, index, url, current, stateValue) =>
  newStateIndex === -1
    ? current.url !== url ||
      (current.url === url &&
        current.stateValue === stateValue &&
        current.stateValue !== undefined) ||
      (current.url === url && stateValue === undefined)
    : index < newStateIndex;

const breadcrumbsReducer = handleActions(
  {
    [setBreadcrumbInitial]: (state, { payload: current }) => ({
      ...state,
      current,
      previously: [],
      stateValue: '0',
    }),
    [setBreadcrumb]: (state, { payload: current }) => {
      if (state?.current) {
        const isCurrentArray = Array.isArray(state?.current);
        const previouslyState = state?.previously ?? [];
        const currentState = state?.current ?? [];

        const mixedState = isCurrentArray
          ? [...previouslyState, ...currentState]
          : [...previouslyState, currentState];

        const getUrl = (url) =>
          url?.split('/').at(-1) === 'systems'
            ? url
                .split('/')
                .splice(
                  0,
                  url.split('/').findIndex((link) => link === 'systems'),
                )
                .join('/')
            : url;

        const newStateIndex = mixedState
          ?.map(({ url }) => getUrl(url))
          ?.indexOf(getUrl(current.url));

        const newState = mixedState
          .filter(({ url }) => url !== current.url)
          .filter(({ url, stateValue, name }, index) =>
            newStateHandler(newStateIndex, index, url, current, stateValue),
          )
          .filter(({ name }) => name);
        return {
          ...state,
          current,
          previously: newState,
          stateValue: '0',
        };
      }
      return initialState;
    },
    [setBreadcrumbStateValue]: (state, { payload: stateValue }) => ({
      ...state,
      stateValue,
    }),
    [setBreadcrumbEmpty]: () => ({
      initialState,
    }),
  },
  initialState,
);

export default breadcrumbsReducer;
