import { createActions } from 'redux-actions';
import { identity } from '../../utils';

export const {
  setActiveDashboard,
  setToggleAccessible,
  setMachineSubscriptionStatusesLoaded,
  setControlSubscriptionStatusesLoaded,
  setTrendViewState,
} = createActions({
  SET_ACTIVE_DASHBOARD: identity,
  SET_TOGGLE_ACCESSIBLE: identity,
  SET_MACHINE_SUBSCRIPTION_STATUSES_LOADED: identity,
  SET_CONTROL_SUBSCRIPTION_STATUSES_LOADED: identity,
  SET_TREND_VIEW_STATE: identity,
});
