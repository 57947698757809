import React, { useEffect, useMemo, useState } from 'react';
import { deriveIDWithType, AggregateType } from 'pc-id';
import {
  extendedApi,
  useGetSystemByIdQuery,
  useGetSystemsQuery,
} from '../redux/services/systems/api';
import { useGetInstrumentsByAggregateQuery } from '../redux/services/instruments/api';
import { useGetAttributeByAggregateQuery } from '../redux/services/attributes';
import { apiBaseUrlV1 } from '../env';
import { useAppDispatch, useAppSelector } from '../redux/store';
import PhaseEventSourceComponent from './PhaseEventSourceComponent';
import { IAppState } from '../typescript/interfaces/appstate.interface';
import { getPropertyName } from '../utils/helper';

interface Props {
  system: string;
}

const SystemComponentsSubscription = (props: Props) => {
  const { system } = props;

  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );
  const dispatch = useAppDispatch();

  const { data: systemSelector } = useGetSystemsQuery(undefined, {
    selectFromResult: (res) => ({
      ...res,
      data: res?.data?.find(({ id }) => id === system),
    }),
  });
  const { data: systemDetails } = useGetSystemByIdQuery(system);
  const { data: instruments } = useGetInstrumentsByAggregateQuery(system);
  const { data: attributes } = useGetAttributeByAggregateQuery(system);
  const [message, setMessage] = useState();

  const handleMessage = (newMessage: any) => {
    setMessage(newMessage);
  };

  const components = useMemo(
    () => systemDetails?.components.filter(({ name }) => name !== 'phase'),
    [systemDetails],
  );

  const componentsStatesObject = useMemo(() => {
    if (components === undefined) return undefined;
    if (instruments === undefined) return undefined;
    if (attributes === undefined) return undefined;

    const componentsWithInstrument = components.map((component) => ({
      ...component,
      instruments: instruments.filter(({ parentAggregate, name }) => {
        if (component?.visual?.inputs)
          return (
            parentAggregate === component.id &&
            Object.values(component?.visual?.inputs)?.find(
              (v) => getPropertyName(v as string, 'instrument') === name,
            )
          );
        return parentAggregate === component.id;
      }),
    }));

    const componentsWithAttribute = componentsWithInstrument
      .filter((component) => component.instruments.length > 0)
      .map((component) => ({
        ...component,
        attributes: attributes.filter(({ parentAggregate, name }) => {
          if (component?.visual?.inputs)
            return (
              component.instruments.find(({ id }) => parentAggregate === id) &&
              Object.values(component?.visual?.inputs)?.find(
                (v) => getPropertyName(v as string, 'attribute') === name,
              )
            );
          return parentAggregate === component.id;
        }),
      }));
    const componentsWithControlAttribute = componentsWithAttribute.map(
      (component) => {
        if (!component?.attributes) return component;
        return {
          ...component,
          controlAttributes: component?.attributes.map(({ id }) =>
            deriveIDWithType(id, AggregateType.CATR),
          ),
        };
      },
    );

    return componentsWithControlAttribute;
  }, [components, attributes, instruments]);

  const url = useMemo(() => {
    if (
      componentsStatesObject === undefined ||
      componentsStatesObject?.length === 0
    ) {
      return undefined;
    }

    return `${apiBaseUrlV1(
      'stream/v2',
    )}/attributes/streams/json?${componentsStatesObject
      .filter(({ controlAttributes }) => controlAttributes)
      .map(({ controlAttributes }) => controlAttributes.map((v) => `id=${v}`))
      .flat(2)
      .join(
        '&',
      )}&rangeIsLive=true&samplerEnabled=true&samplerType=down&samplerMethod=latest&samplerInterval=1s`;
  }, [componentsStatesObject]);

  useEffect(() => {
    if (message) {
      // @ts-ignore
      const parsedMessage = JSON.parse(message.data);
      const { id: cAttrId } = parsedMessage;
      const component = componentsStatesObject.find(
        (c) => c.controlAttributes.indexOf(cAttrId) > -1,
      );
      const state = JSON.parse(JSON.parse(parsedMessage.state));

      if (JSON.stringify(state) !== JSON.stringify(component.value))
        dispatch(
          extendedApi.util.updateQueryData(
            'getSystemById',
            system,
            (systemDraft) => {
              const comp = systemDraft?.components?.map((s) => {
                if (
                  s.id === component.id &&
                  JSON.stringify(s.value) !== JSON.stringify(state) &&
                  component.controlAttributes.indexOf(cAttrId) > -1
                ) {
                  return {
                    ...s,
                    value: state,
                  };
                }
                return s;
              });
              return { ...systemDraft, components: comp };
            },
          ),
        );
    }
  }, [message, componentsStatesObject]);

  if (systemSelector?.machineState !== 'Online' && !isEdgeEnv) return null;

  if (!url) return null;

  return (
    <PhaseEventSourceComponent
      deleteStreamData={() => {}}
      handleStreamData={handleMessage}
      eventKeyType="/pc-domain/AttributeValueObject"
      urlProp={url}
    />
  );
};

export default SystemComponentsSubscription;
